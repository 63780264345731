import React from 'react';
import { FormCard } from '../../Components/FormCard/FormCard';
import { SkeletonBlock } from '../../Components/SkeletonBlock/SkeletonBlock';

export default function(){
	return (
		<div className="container-fluid py-2">
			<FormCard header={<SkeletonBlock height='60px' width='100%' /> }>
				<div className="row justify-content-center mb-2">
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
				</div>
			</FormCard>
			<FormCard header={<SkeletonBlock height='60px' width='100%' /> }>
				<div className="row justify-content-center mb-2">
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
					<div className="col-12 col-md-6">
						<SkeletonBlock height='20px' width='70px' /><br/>
						<SkeletonBlock height='40px' width='100%' />
					</div>
				</div>
			</FormCard>
		</div>
	);
};
