import React, { useCallback, useState } from "react";
import "./FormCard.scss";

export const FormCard = React.memo(function ({ header, children }) {
  const [show, setShow] = useState(true);
  const toggleShow = useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <div className="form-card">
      {header !== void 0 && (
        <div className="form-title" onClick={toggleShow}>
          {header}
        </div>
      )}
      <div className={`form-body${show ? "" : " d-none"}`}>{children}</div>
    </div>
  );
});
