import "./MainContent.css";
import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { LazyLoader } from "../../Components/LazyLoader/LazyLoader";
import LoginSkeleton from "../../Routes/Login/LoginSkeleton";
import ABMSkeleton from "../../Routes/ABM/ABMSkeleton";
import ABMUpdateSkeleton from "../../Routes/ABMUpdate/ABMUpdateSkeleton";
import ABMDeleteSkeleton from "../../Routes/ABMDelete/ABMDeleteSkeleton";
import MyUserSkeleton from "../../Routes/MyUser/MyUserSkeleton";
import { ErrorScreen } from "../../Components/ErrorScreen/ErrorScreen";
import { Modal } from "../../Components/Modal/Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useUserPermissions } from "../../Hooks/useUserPermission";

library.add(faTimes);

const _permisos = ["logged", "permisos/get/search", "log/get/search"];

export const MainContent = React.memo(function () {
  const renderArea = useRef(null);

  const history = useHistory();
  const location = useLocation();
  const background = location.state && location.state.background;

  const [isLogged, canSeePermisos, canSeeLog] = useUserPermissions(_permisos);

  const [prevPath, setPrevPath] = useState(location.pathname);

  const goBack = useCallback(
    (e) => {
      if (history.length === 0) {
        history.push("/");
      } else {
        history.goBack();
      }
    },
    [history]
  );

  useEffect(() => {
    if (background || prevPath === location.pathname) return;

    renderArea.current.scrollTop = 0;
    setPrevPath(location.pathname);
  }, [prevPath, background, location.pathname]);

  const routes = [
    <Route key="404" path="/404">
      <ErrorScreen
        title={"notfound.error.title"}
        description={"notfound.error.description"}
        action={"notfound.error.action"}
      />
    </Route>,
  ];

  if (isLogged === null) return;

  if (!isLogged) {
    routes.push(
      <Route key="login" path="/" exact={true}>
        <LazyLoader
          component={() => import("../../Routes/Login/Login")}
          fallback={<LoginSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="matchAll" path="*">
        <Redirect to={{ pathname: "/404" }} />
      </Route>
    );
  } else {
    if (canSeeLog)
      routes.push(
        <Route key="log" path="/log">
          <LazyLoader
            component={() => import("../../Routes/ABMLogs")}
            fallback={<ABMSkeleton />}
          />
        </Route>
      );

    if (canSeePermisos)
      routes.push(
        <Route key="permisos" path="/permisos">
          <LazyLoader
            component={() => import("../../Routes/ABMPermisos")}
            fallback={<ABMSkeleton />}
          />
        </Route>
      );

    routes.push(
      <Route key="abm" path="/abm/:table">
        <LazyLoader
          component={() => import("../../Routes/ABM/ABM")}
          fallback={<ABMSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="abmupdate" path="/abmupdate/:table/:id">
        <LazyLoader
          component={() => import("../../Routes/ABMUpdate/ABMUpdate")}
          fallback={<ABMUpdateSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="abmdelete" path="/abmdelete/:table/:id">
        <LazyLoader
          component={() => import("../../Routes/ABMDelete/ABMDelete")}
          fallback={<ABMDeleteSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="/deletecubierta/:id" path="/deletecubierta/:id">
        <LazyLoader
          component={() => import("../../Routes/CubiertaDelete")}
          fallback={<ABMUpdateSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="myuser" path="/miusuario">
        <LazyLoader
          component={() => import("../../Routes/MyUser/MyUser")}
          fallback={<MyUserSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="iosInstall" path="/iosInstall">
        <LazyLoader
          component={() => import("../../Routes/iOSInstall/iOSInstall")}
          fallback={<MyUserSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="wall" path="/" exact={true}>
        <LazyLoader
          component={() => import("../../Routes/Wall/Wall")}
          fallback={<ABMSkeleton />}
        />
      </Route>
    );

    routes.push(
      <Route key="matchAll" path="*">
        <Redirect to={{ pathname: "/404" }} />
      </Route>
    );
  }

  return (
    <div ref={renderArea} className="main-content">
      <Switch location={background || location}>{routes}</Switch>
      {background && (
        <Modal outCallback={goBack} iconButton="times">
          <Switch>{routes}</Switch>
        </Modal>
      )}
    </div>
  );
});
