const langs = {};
const getting = {};
const Resolvers = new Map();

const _getLang = function (key) {
  if (key in getting && getting[key]) return;

  getting[key] = true;

  import(`./${key}.json`)
    .then((asset) => {
      getting[key] = false;
      langs[key] = asset.default;
      Resolvers.get(key).map(({ resolve }) => resolve(langs[key]));
      Resolvers.get(key).length = 0;
    })
    .catch((e) => {
      getting[key] = false;
      Resolvers.get(key).map(({ reject }) => reject(langs[key]));
      Resolvers.get(key).length = 0;
    });
};

export const getLang = function (key) {
  if (key in langs) return Promise.resolve(langs[key]);

  return new Promise((resolve, reject) => {
    if (!Resolvers.has(key)) Resolvers.set(key, []);

    Resolvers.get(key).push({ resolve, reject });
    _getLang(key);
    return;
  });
};
