import React, { useEffect, useState } from "react";
import "./App.scss";
import "../Forms/Forms.scss";
import { useSelector, useDispatch } from "react-redux";
import { Sidebar } from "../Sidebar/Sidebar";
import { Overlay } from "../Sidebar/Overlay";
import { Header } from "../Header/Header";
import { MainContent } from "../../BusinessLogic/MainContent/MainContent";
import { MenuLinks } from "../../BusinessLogic/MenuLinks/MenuLinks";
import { useHistory } from "react-router-dom";
import "moment/locale/es";
import config from "../../config";
import { setEndpoint } from "../../Store/actions/pusher";
import { Auth } from "../../Lib/Auth";
import { useUserPermissions } from "../../Hooks/useUserPermission";

const ActiveApp = React.memo(function ({ isLogged }) {
  return (
    <>
      {isLogged && (
        <>
          <Sidebar>
            <MenuLinks />
          </Sidebar>
          <Header />
        </>
      )}

      <Overlay />
      <MainContent />
    </>
  );
});

const neededPermissions = ["logged"];

const App = React.memo(function () {
  const history = useHistory();

  const [isLogged] = useUserPermissions(neededPermissions);

  const pusher = useSelector((state) => state.pusher);
  const [pusherDone, setPusherDone] = useState(false);
  const [doingPusher, setDoingPusher] = useState(false);
  const [initialAuth, setInitialAuth] = useState(isLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    if (doingPusher || !isLogged) return;

    if (pusher.enabled) {
      if (pusherDone) return;

      setDoingPusher(true);

      navigator.serviceWorker.ready
        .then((reg) =>
          reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.VAPIDPublicKey,
          })
        )
        .then((sub) => {
          dispatch(setEndpoint(sub.endpoint));
          return Auth.updatePusherEndpoint(sub.toJSON());
        })
        .then((r) => {
          setPusherDone(true);
          setDoingPusher(false);
        })
        .catch((e) => {
          setDoingPusher(false);
        });
    } else if (pusher.endpoint.length > 1) {
      setDoingPusher(true);

      Auth.unsubPusher(pusher.endpoint)
        .then(() => {
          dispatch(setEndpoint(""));
          setDoingPusher(false);
          setPusherDone(false);
        })
        .catch((e) => {
          dispatch(setEndpoint(""));
          setDoingPusher(false);
          setPusherDone(false);
        });
    }
  }, [pusherDone, doingPusher, pusher, isLogged, dispatch]);

  const theme = useSelector((state) => state.theme.color);

  useEffect(() => {
    if (initialAuth && !isLogged) history.push("/");

    setInitialAuth(isLogged);
  }, [initialAuth, isLogged, history]);

  return (
    <div className={`App theme-${theme}`}>
      <div className="headwrap"></div>
      <div className={`app-container${isLogged ? " logged" : ""}`}>
        <ActiveApp isLogged={isLogged} />
      </div>
    </div>
  );
});

export default App;
