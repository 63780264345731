import { useReducer } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const hasPermission = function (user, permission) {
  if (!Array.isArray(user.permisos) || user.permisos.length === 0) return false;

  const does =
    user.permisos.includes(permission) || user.permisos.includes("all");
  return does;
};

export const useUserPermission = function (permission = "") {
  const { logged, user } = useSelector((state) => state.auth);
  if (!logged) return false;

  return hasPermission(user, permission);
};

const initPermisoState = (permissions) => Array(permissions.length).fill(null);

const permissionsReducer = (permisos, { permissions, logged, user }) => {
  if (!logged) return Array(permisos.length).fill(false);

  const hasPermissionOnUser = hasPermission.bind(null, user);
  return permissions.map(hasPermissionOnUser);
};

export const useUserPermissions = function (permissions = []) {
  const [permisos, dispatchPermiso] = useReducer(
    permissionsReducer,
    permissions,
    initPermisoState
  );

  const { logged, user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatchPermiso({ permissions, logged, user });
  }, [logged, user, permissions]);

  return permisos;
};
